import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';
import { showNotification } from "./notificationSlice";
import store from "../index";
import CampaignService from "../../services/CampaignService";
import FileService from 'services/FileService';
import i18n from "i18next";

export const getAllCampaign = createAsyncThunk('campaign/getAllAdminUsers', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await CampaignService.getAllCampaign(order, page, take, filter);
        if (response.status === 200) {
            return {
                campaign: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to fetch adminUsers');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addCampaign = createAsyncThunk('campaign/addCampaign', async (data, { rejectWithValue }) => {
    try {
        const { file, ...fields } = data;
        const response = await CampaignService.addCampaign(fields);
        if (response.status === 201) {
            const result = response.data.data;
            const id = result.id;
            try {
                await FileService.upload(file, id);
            } catch (e) {
                try {
                    await CampaignService.deleteCampaign(id);
                } catch (x) { }

                return rejectWithValue('Failed to add campaign');
            }
            return result;
        } else {
            return rejectWithValue('Failed to add campaign');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const detailCampaign = createAsyncThunk('campaign/updateCampaign', async (id, { rejectWithValue }) => {
    try {
        const response = await CampaignService.detailCampaign(id);
        if (response.status === 200) {
            return {
                campaignDetail: response.data.data,
            };
        } else {
            return rejectWithValue('Failed to update campaign');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, { rejectWithValue }) => {
    try {
        const response = await CampaignService.deleteCampaign(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete campaign');
        }
    } catch (error) {
        store.dispatch(showNotification({
            message: 'Error',
            description: i18n.t(error.response?.data?.message) || 'Network Error',
            type: 'error',
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        campaign: [],
        campaignDetails: null,
        total: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCampaign.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCampaign.fulfilled, (state, action) => {
                state.campaign = action.payload.campaign;
                state.total = action.payload.total;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllCampaign.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteCampaign.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCampaign.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCampaign.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign.push(action.payload);
                state.total += 1;
            })
            .addCase(addCampaign.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(detailCampaign.pending, (state) => {
                state.loading = true;
                state.campaignDetails = null;
            })
            .addCase(detailCampaign.fulfilled, (state, action) => {
                state.campaignDetails = action.payload.campaignDetail;
                state.loading = false
            })
            .addCase(detailCampaign.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default campaignSlice.reducer;
