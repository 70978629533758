import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "services/AuthService";

export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthService.requestPasswordReset(data);
            if (response.status === 200) {
                return response.data; // Başarılı ise direkt datayı döndür
            } else {
                return rejectWithValue(response.data.message || "Network Error");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Network Error");
        }

    }
);
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthService.resetPasswordWithCode(data);

            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data.message || "Network Error");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Network Error");
        }
    }
);


export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: {
        loading: false,
        message: '',
        showMessage: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(requestPasswordReset.pending, (state) => {
            })
            .addCase(requestPasswordReset.fulfilled, (state) => {
                state.redirect = null;
                state.redirect = '/auth/reset-password';
            })
            .addCase(requestPasswordReset.rejected, (state, action) => {
                state.redirect = null;
                state.message = action.payload;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.message='Başarılı';
                state.redirect = '/auth/login';
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
            });
    }
});



export default forgotPasswordSlice.reducer;
