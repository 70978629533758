import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.map',
        path: `${APP_PREFIX_PATH}/dashboards/map`,
        component: React.lazy(() => import('views/app-views/dashboards/map')),
    },
    {
        key: 'dashboard.scooter-management',
        path: `${APP_PREFIX_PATH}/dashboards/scooter-management`,
        component: React.lazy(() => import('views/app-views/dashboards/scooter-management')),
    },
    {
        key: 'dashboard.scooter-detail',
        path: `${APP_PREFIX_PATH}/dashboards/scooter-detail/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/scooter-detail')),
    },
    {
        key: 'dashboard.transaction-detail',
        path: `${APP_PREFIX_PATH}/dashboards/transaction-detail/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/transaction-detail')),
    },
    {
        key: 'dashboard.fault',
        path: `${APP_PREFIX_PATH}/dashboards/fault`,
        component: React.lazy(() => import('views/app-views/dashboards/faults')),
    },
    {
        key: 'dashboard.scooter-models-management',
        path: `${APP_PREFIX_PATH}/dashboards/scooter-models-management`,
        component: React.lazy(() => import('views/app-views/dashboards/scooter-models-management')),
    },
    {
        key: 'dashboard.user-management',
        path: `${APP_PREFIX_PATH}/dashboards/user-management`,
        component: React.lazy(() => import('views/app-views/dashboards/user-management')),
    },
    {
        key: 'dashboard.scooter-transaction-management',
        path: `${APP_PREFIX_PATH}/dashboards/scooter-transaction-management`,
        component: React.lazy(() => import('views/app-views/dashboards/scooter-transaction-management')),
    },
    {
        key: 'dashboard.admin-user-management',
        path: `${APP_PREFIX_PATH}/dashboards/admin-user-management`,
        component: React.lazy(() => import('views/app-views/dashboards/admin-user-management')),
    },
    {
        key: 'dashboard.ticket-management',
        path: `${APP_PREFIX_PATH}/dashboards/ticket-management`,
        component: React.lazy(() => import('views/app-views/dashboards/ticket')),
    },
    {
        key: 'dashboard.reports-user',
        path: `${APP_PREFIX_PATH}/dashboards/reports/user`,
        component: React.lazy(() => import('views/app-views/dashboards/reports/user-report')),
    },
    {
        key: 'dashboard.reports-scooter',
        path: `${APP_PREFIX_PATH}/dashboards/reports/scooter`,
        component: React.lazy(() => import('views/app-views/dashboards/reports/scooter-report')),
    },
    {
        key: 'dashboard.logs-user',
        path: `${APP_PREFIX_PATH}/dashboards/logs/user`,
        component: React.lazy(() => import('views/app-views/dashboards/logs/user-logs')),
    },
    {
        key: 'dashboard.logs-scooter',
        path: `${APP_PREFIX_PATH}/dashboards/logs/scooter`,
        component: React.lazy(() => import('views/app-views/dashboards/logs/scooter-logs')),
    },
    {
        key: 'dashboard.logs-admin',
        path: `${APP_PREFIX_PATH}/dashboards/logs/admin`,
        component: React.lazy(() => import('views/app-views/dashboards/logs/admin-logs')),
    },
    {
        key: 'dashboard.campaign-management',
        path: `${APP_PREFIX_PATH}/dashboards/campaign-management`,
        component: React.lazy(() => import('views/app-views/dashboards/campaign')),
    },
    {
        key: 'views.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/settings')),
    }
]