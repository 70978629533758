import {
  ProductFilled,
  DashboardOutlined,
  BranchesOutlined,
  UserOutlined,
  EnvironmentFilled,
  HarmonyOSOutlined,
  GroupOutlined,
  WarningOutlined, ContainerOutlined, BarChartOutlined, PhoneOutlined, UsergroupAddOutlined, MutedOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: '',
  icon: DashboardOutlined,
  breadcrumb: false,
  isGroupTitle: true,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'dashboard',
      icon: ProductFilled,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-map',
      path: `${APP_PREFIX_PATH}/dashboards/map`,
      title: 'sidenav.dashboard.map',
      icon: EnvironmentFilled,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-scooter-management',
      path: `${APP_PREFIX_PATH}/dashboards/scooter-management`,
      title: 'scooter_management',
      icon: BranchesOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-fault',
      path: `${APP_PREFIX_PATH}/dashboards/fault`,
      title: 'faults',
      icon: WarningOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-scooter-models-management',
      path: `${APP_PREFIX_PATH}/dashboards/scooter-models-management`,
      title: 'scooter_model_management',
      icon: HarmonyOSOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-scooter-transaction-management',
      path: `${APP_PREFIX_PATH}/dashboards/scooter-transaction-management`,
      title: 'scooter_transaction_management',
      icon: GroupOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-user-management',
      path: `${APP_PREFIX_PATH}/dashboards/user-management`,
      title: 'user_management',
      icon: UsergroupAddOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-admin-user-management',
      path: `${APP_PREFIX_PATH}/dashboards/admin-user-management`,
      title: 'admin_user_management',
      icon: UserOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-ticket-management',
      path: `${APP_PREFIX_PATH}/dashboards/ticket-management`,
      title: 'ticket_management',
      icon: PhoneOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-campaign-management',
      path: `${APP_PREFIX_PATH}/dashboards/campaign-management`,
      title: 'campaign_management',
      icon: MutedOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-reports',
      path: `${APP_PREFIX_PATH}/dashboards/reports`,
      title: 'reports',
      icon: BarChartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'dashboards-reports-user',
          path: `${APP_PREFIX_PATH}/dashboards/reports/user`,
          title: 'user_reports',
          icon: BarChartOutlined,
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-scooter',
          path: `${APP_PREFIX_PATH}/dashboards/reports/scooter`,
          title: 'scooter_reports',
          icon: BarChartOutlined,
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'dashboards-logs',
      path: `${APP_PREFIX_PATH}/dashboards/logs`,
      title: 'logs',
      icon: ContainerOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'dashboards-logs-user',
          path: `${APP_PREFIX_PATH}/dashboards/logs/user`,
          title: 'user_logs',
          icon: ContainerOutlined,
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-logs-scooter',
          path: `${APP_PREFIX_PATH}/dashboards/logs/scooter`,
          title: 'scooter_logs',
          icon: ContainerOutlined,
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-logs-admin',
          path: `${APP_PREFIX_PATH}/dashboards/logs/admin`,
          title: 'admin_logs',
          icon: ContainerOutlined,
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

var navigationConfig = [
  ...dashBoardNavTree
]

const setHiddenItems = (user) => {
  navigationConfig = [
    ...dashBoardNavTree
  ]

  const findAndDeleteMain = (key) => {
    const idx = navigationConfig[0]['submenu'].findIndex((e) => e.key == key);
    if (idx >= 0) {
      navigationConfig[0]['submenu'].splice(idx, 1);
    }
  }


  const findAndDeleteSub = (mainKey, subKey) => {
    const idx = navigationConfig[0]['submenu'].findIndex((e) => e.key == mainKey);
    if (idx >= 0) {
      const idx2 = navigationConfig[0]['submenu'][idx]['submenu'].findIndex((e) => e.key == subKey);
      if (idx2 >= 0) {
        navigationConfig[0]['submenu'][idx]['submenu'].splice(idx2, 1);
      }
    }
  }
 
  switch (user?.role) {
    case 'supervisor':
      findAndDeleteMain('dashboards-default');
      break;
    case 'call_center':
      findAndDeleteMain('dashboards-default');
      findAndDeleteMain('dashboards-admin-user-management');
      findAndDeleteMain('dashboards-reports');
      findAndDeleteSub('dashboards-logs', 'dashboards-logs-admin');
      break;
    case 'field_operation':
      findAndDeleteMain('dashboards-default');
      findAndDeleteMain('dashboards-scooter-management');
      findAndDeleteMain('dashboards-scooter-models-management');
      findAndDeleteMain('dashboards-scooter-transaction-management');
      findAndDeleteMain('dashboards-ticket-management');
      findAndDeleteMain('dashboards-campaign-management');
      findAndDeleteMain('dashboards-user-management');
      findAndDeleteMain('dashboards-admin-user-management');
      findAndDeleteMain('dashboards-reports');

      findAndDeleteMain('dashboards-logs');
      break;
    case 'technical_responsible':
      findAndDeleteMain('dashboards-default');
      break;
  }
}

export { navigationConfig, setHiddenItems };
